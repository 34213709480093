










































































@import '@design';

.bloak-page-section {
  color: $color-bloak-blue;
  background-color: $color-bloak-eggnog;
  .bloak-section--title {
    padding-bottom: 0.5em;
    border-bottom: 13px solid $color-bloak-piggy-pink;
  }
}
